<template>
	<el-container>
		<el-main>
			<el-card shadow="never">
				<el-form ref="form" :model="form" label-width="120px">
					<el-row :gutter="10">
						<el-col :span="6" :xs="24">
							<el-form-item label="观看视频数<="><el-input v-model="form.playedNum" clearable placeholder="请输入观看视频数<="></el-input></el-form-item>
						</el-col>
						<el-col :span="6" :xs="24">
							<el-form-item label="过关数<="><el-input v-model="form.beatStageNum" clearable placeholder="请输入过关数<="></el-input></el-form-item>
						</el-col>
						<el-col :span="12" :xs="24"><el-button type="primary" @click="jouShare">搜索</el-button></el-col>
					</el-row>
				</el-form>
				<!-- table 表格 -->
				<el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }" border stripe>
					<el-table-column prop="day" label="日期"></el-table-column>
					<el-table-column prop="addNum" label="新增用户数"></el-table-column>
					<el-table-column prop="num" label="满足条件用户数"></el-table-column>
					<el-table-column label="比例">
						<template v-slot="scope">
							<span v-if="scope.row.num == 0">0%</span>
							<span v-else>{{ ((scope.row.num / scope.row.addNum) * 100).toFixed(2) }}%</span>
						</template>
					</el-table-column>
					<el-table-column prop="averagePlayedNum" label="平均视频数"></el-table-column>
					<el-table-column prop="averageBeatStageNum" label="平均过关数"></el-table-column>
					<template #empty>
						<el-empty :description="emptyText" :image-size="100"></el-empty>
					</template>
				</el-table>
			</el-card>
		</el-main>
	</el-container>
</template>

<script>
export default {
	name: 'userBehavior',
	data() {
		return {
			emptyText: '暂无数据',
			tableData: [],
			serloading: false,
			productId: '',
			form: {
				playedNum: '0',
				beatStageNum: ''
			}
		};
	},
	created() {
		this.productId = this.$TOOL.data.get('DATA_SELECTEED');
		// 新增用户-行为占比
		this.Cluserlist();
	},
	watch: {
		'$store.state.dataSelected'(newval) {
			if (this.productId != newval) {
				this.productId = newval;
				// 新增用户-行为占比
				this.Cluserlist();
			}
		}
	},
	methods: {
		// 新增用户-行为占比
		Cluserlist() {
			this.serloading = true;
			this.$HTTP.post(
				'userBehavior/query',
				{
					productId: this.productId,
					playedNum: this.form.playedNum,
					beatStageNum: this.form.beatStageNum
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.tableData = response.data.data;
							this.serloading = false;
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},
		// 搜索
		jouShare() {
			this.serloading = true;
			// 新增用户-行为占比
			this.Cluserlist();
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
